const getDesignTokens = (mode) => ({
    palette: {
        mode,
        ...(mode === 'light'
        ? {
            primary: {
                main: '#00416D',
              },
              background: {
                default: '#FBF6F0',
              },
        }
        : {
            primary: {
                main: '#892CDC',
              },
              secondary: {
                main: '#52057B'
              },
              background: {
                default: '#000000',
                paper: '#D5DCF9',
              },
        })
    }
        
});


export default getDesignTokens;