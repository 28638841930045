import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import getDesignTokens from './theme/theme';
import { useState } from 'react';


import Home from './pages/Home'
import Resume from './pages/Resume'
const App = () => {
  const [mode, setMode] = useState('light');

  const toggleColorMode = () => {
    setMode((prevMode) =>
      prevMode === 'light' ? 'dark' : 'light',
    );
  }

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home toggleColorMode={toggleColorMode}/>}/>
        <Route path="CV" element={<Resume/>} />
        <Route path="contact" /*element={<Contact />}*/ />
        <Route path="*"/>
      </Routes>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
