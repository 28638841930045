import resume from './resume.pdf'
import {styled} from "@mui/material/styles";

const PageLayoutRoot = styled('div')(({theme}) => ({
    display: 'flex',
    flex: '1 1 auto',
    width: "100%",
    height: "86.1vh",
    justifyContent: "center",
}));

const Resume = () => {

    return(
        <PageLayoutRoot>
            <iframe
                title='pdfviewer'
                id='frame'
                src={resume}
                frameBorder="0"
                style={{
                    width: "100%",
                    height: "100vh",
                }}    
            ></iframe>
        </PageLayoutRoot>
    );
}

export default Resume